import { ArticleList } from "app/articles/components/ArticleList"
import { ArticleVM } from "app/models/article"
import { LocaleD } from "db"
import { FC } from "react"
import { tw } from "twind"

export interface ArticleBigListProps {
  articles: ReadonlyArray<ArticleVM>
  isFirstHighlighted?: boolean
  locale: LocaleD
  size?: "sm" | "lg"
}

export const ArticleBigList: FC<ArticleBigListProps> = ({
  articles,
  isFirstHighlighted = false,
  locale,
  size = "lg",
}) => {
  return (
    <div
      className={tw(
        `ram-${size}`,
        isFirstHighlighted && `md:(children:first:(col-span-2 row-span-2))`,
        `select-none`
      )}
    >
      <ArticleList articles={articles} isFirstHighlighted={isFirstHighlighted} locale={locale} />
    </div>
  )
}
