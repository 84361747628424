import { LocaleD } from "@prisma/client"
import { ArticleBigListProps } from "app/articles/components/ArticleBigList"
import { NortherPageProps } from "app/common/components/NortherPageProps"
import { justThrow } from "app/common/fp-ts"
import { logException } from "app/common/logging"
import { bindCommon } from "app/common/next"
import { UrlMgr } from "app/common/routes"
import { createSeoMetadata } from "app/common/seo/seoEssentials"
import { getStaticPropsResult } from "app/common/ui/next"
import { toTitleString } from "app/common/web"
import { getRecentArticles } from "app/data/articles/article.queries"
import { HomeDetails } from "app/home/components/HomeDetails"
import BlogLayout from "app/layouts/BlogLayout"
import { BlitzPage, GetStaticProps } from "blitz"
import { option, task, taskEither } from "fp-ts"
import { pipe } from "fp-ts/function"
import React from "react"

type HomePageProps = ArticleBigListProps & NortherPageProps

// ts-prune-ignore-next
export const getStaticProps: GetStaticProps<HomePageProps> = ({ locale }) =>
  pipe(
    taskEither.Do,
    bindCommon(locale),
    taskEither.bind("articles", ({ locale }) => getRecentArticles({ locale })),
    taskEither.bind("seoMetadata", ({ locale, ogLocale, t }) =>
      pipe(
        {
          canonicalUrl: UrlMgr.Home({ locale }),
          metaDescription: `Discover and learn about Canadian brands and services with Norther. Buy Canadian.`,
          openGraphMetadata: {
            description: `Discover and learn about Canadian brands and services with Norther. Buy Canadian.`,
            locale: ogLocale,
            siteName: "Norther",
            title: toTitleString([`Norther`, t("buy-local-buy-canadian")]),
            type: option.none,
            url: UrlMgr.Home({ locale }),
          },
          title: toTitleString([`Norther`, t("buy-local-buy-canadian")]),
          urlMap: {
            en_ca: UrlMgr.Home(),
            fr_ca: UrlMgr.Home({ locale: LocaleD.fr_ca }),
            "x-default": UrlMgr.Home(),
          },
        },
        createSeoMetadata,
        taskEither.of
      )
    ),
    taskEither.bimap(logException, getStaticPropsResult),
    taskEither.fold(justThrow, task.of)
  )()

const Home: BlitzPage<HomePageProps> = ({ articles, locale }) => {
  return <HomeDetails articles={articles} isFirstHighlighted locale={locale} />
}

Home.suppressFirstRenderFlicker = true

Home.getLayout = BlogLayout.getLayout

// ts-prune-ignore-next
export default Home
