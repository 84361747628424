import { LocaleD } from "@prisma/client"
import { RouteMgr } from "app/common/routes"
import { toDisplayDate } from "app/common/utilities/date"
import { variantsToSrcSet } from "app/common/utilities/html"
import { ArticleVM } from "app/models"
import { Link } from "blitz"
import { array } from "fp-ts"
import { pipe } from "fp-ts/function"
import { FC } from "react"
import { tw } from "twind"
import { css } from "twind/css"

interface ArticleListProps {
  articles: ReadonlyArray<ArticleVM>
  isFirstHighlighted?: boolean
  locale: LocaleD
}

export const ArticleList: FC<ArticleListProps> = ({
  articles,
  isFirstHighlighted = false,
  locale,
}) => (
  <>
    {pipe(
      articles,
      array.mapWithIndex((index, { image, publishedAt, slug, summary, title }) => (
        <article className={tw(`relative`)} key={slug}>
          <Link href={RouteMgr.ArticlePage({ slug })}>
            <a className={tw(`blogLink`)}>
              <img
                alt={image.alternateText}
                className={tw(
                  `text(xs gray-800)`,
                  `object-cover w-full`,
                  css`
                    aspect-ratio: ${isFirstHighlighted && index === 0 ? "3/2" : "16/8"};
                  `
                )}
                height={image.height}
                loading={index > 10 ? "lazy" : undefined}
                sizes={isFirstHighlighted && index === 0 ? "1000px" : "500px"}
                src={image.url}
                srcSet={variantsToSrcSet(image.variants)}
                width={image.width}
              />
              <div className={tw(`bg(white opacity-80)`, `text-gray-800`, `py-3`)}>
                <div className={tw(`text-xl font-semibold`)}>{title}</div>
                <p className={tw(`text-gray-500`)}>{summary}</p>
                <div className={tw(`text-gray-400 text-xs`, `mt-1`)}>
                  {toDisplayDate(publishedAt, locale)}
                </div>
              </div>
            </a>
          </Link>
        </article>
      ))
    )}
  </>
)
