import { Locale as DateFnsLocale } from "date-fns"
import format from "date-fns/format"
import { enCA, frCA } from "date-fns/locale"
import { LocaleD } from "db"

const localeMap: Record<LocaleD, DateFnsLocale> = {
  [LocaleD.en_ca]: enCA,
  [LocaleD.fr_ca]: frCA,
}

export const toDisplayDate = (date: Date, locale: LocaleD): string =>
  format(date, "LLLL d, y", { locale: localeMap[locale] })
